import styled from "@emotion/styled/macro";
import { MQ } from "react-app/src/styles/helpers";
import spaces from "react-app/src/styles/theme/spaces";
import { componentMaxWidth } from "react-app/src/styles/xxl-theme";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InnerWrapper = styled.div<{ hasPadding: boolean }>`
  padding: ${({ hasPadding }) => (hasPadding ? spaces.large : 0)} 0;
  box-sizing: border-box;
  width: 100%;
  max-width: ${componentMaxWidth}px;

  ${MQ("laptop")} {
    padding: ${({ hasPadding }) => (hasPadding ? spaces.almostHuge : 0)} 0;
  }
`;

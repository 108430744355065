import { Product } from "../../../../react-app/src/components/Product";
import { PriceWithLabels } from "../../../../react-app/src/components/Product/PriceWithLabels/PriceWithLabels";
import type { ProductCardDataV2 } from "../../../../react-app/src/utils/ProductData/product-card-data-helper";

type ProductCardV2Props = {
  AddToCartRow?: JSX.Element;
  arrayIndex: number;
  isLaptopViewPort: boolean;
  isSliderProductList: boolean;
  nrOfSelectedColumns: number;
  productData: ProductCardDataV2;
  isHoverable?: boolean;
};

export const ProductCardV2 = ({
  AddToCartRow,
  arrayIndex,
  isLaptopViewPort,
  isSliderProductList,
  nrOfSelectedColumns,
  productData,
  isHoverable,
}: ProductCardV2Props) => {
  const {
    additionalSales,
    campaignHighlightedLabel,
    campaignRibbon,
    price,
    type,
  } = productData;

  const hoverableState =
    isHoverable !== undefined ? isHoverable : isLaptopViewPort;

  const nrOfImagesToPrefetch = isLaptopViewPort ? 6 : 2;
  const isPrio = arrayIndex !== -1 && arrayIndex < nrOfImagesToPrefetch;

  return (
    <Product
      additionalSales={additionalSales}
      AddToCartRow={AddToCartRow}
      isHoverable={hoverableState}
      isSliderProductList={isSliderProductList}
      PriceComponent={
        <PriceWithLabels
          version={2}
          selectedColumnsNumber={nrOfSelectedColumns}
          priceData={price}
          productType={type}
        />
      }
      priceSplash={campaignRibbon?.content?.text}
      showHighlightedLabel={true} // TODO: https://xxlsports.atlassian.net/browse/XD-16091
      highlightedLabel={campaignHighlightedLabel?.content?.text}
      campaignColorThemeName={
        campaignRibbon?.content?.name ??
        campaignHighlightedLabel?.content?.name ??
        "green"
      }
      product={productData}
      productMetaData={{
        list: "search",
        pageType: "search",
        position: arrayIndex + 1,
      }}
      selectedFilters={[]} // TODO: XD-14109
      showFavoritesHeart={true}
      selectedColumnsNumber={nrOfSelectedColumns}
      prioritizeImageLoad={isPrio}
      positionInList={arrayIndex}
    />
  );
};
